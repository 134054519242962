export enum EnumFeedAction {
  Shared = 'shared',
  Clicked = 'clicked',
  Viewer = 'viewer',
}

export enum EnumFeedActionShared {
  Facebook = 'Facebook',
  Telegram = 'Telegram',
  Twitter = 'twitter',
}
